<template>
  <div>
    <HeaderPanel
      title="Custom Object"
      :filter="filter"
      placeholder="Search Custom Object"
      @search="getData"
      :hasDropdown="false"
      :hasFilter="false"
      routerPath="/custom-object/detail/0"
    />
    <div class="bg-white border-red p-2 p-lg-3 mt-3">
      <Table
        :filter="filter"
        :items="items"
        :isBusy="isBusy"
        :rows="rows"
        :showingTo="showingTo"
        @filterPage="filterPage"
        @deleteForm="deleteForm"
      />
    </div>
  </div>
</template>
<script>
import HeaderPanel from "@/components/HeaderPanel";
import OtherLoading from "@/components/loading/OtherLoading";
import Table from "./components/Table";
import SideBarFilter from "@/components/SideBarFilter";

export default {
  components: {
    OtherLoading,
    HeaderPanel,
    SideBarFilter,
    Table,
  },
  data() {
    return {
      filter: {
        page: 1,
        take: 10,
        search: "",
      },
      isBusy: false,
      rows: 0,
      showing: 1,
      showingTo: 10,

      items: [],
      styleDatetime: {
        width: "150%",
        border: "none",
      },
    };
  },

  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.isBusy = true;
      let payload = { ...this.filter };
      payload.page_size = this.filter.take;
      this.$Axios
        .post(
          `${process.env.VUE_APP_API}/CustomObject/list_custom_object`,
          payload
        )
        .then((res) => {
          if (res.data.result) {
            this.rows = res.data.detail.total;
            this.items = res.data.detail.customObjectLists;
            this.isBusy = false;
          }
        });
    },

    sidebarFilter() {
      this.$refs.sidebarFilter.show();
    },

    filterPage(filter) {
      this.filter = filter;
      this.getData();
    },
    dateFormat() {
      let today = new Date();
      return this.$moment(today).format();
    },
    clearFilter() {
      this.filter = {
        search: "",
        page: 1,
        take: 10,
        status: [],
      };
    },
    async deleteForm(id) {
      try {
        this.$EventBus.$emit("showLoading");
        const resp = await this.$Axios.post(
          `${process.env.VUE_APP_API}/CustomObject/delete_answer/${id}`
        );
        this.getData();
        this.$EventBus.$emit("hideLoading");
      } catch (error) {
        this.errorAlert(error.message);
        this.$EventBus.$emit("hideLoading");
      }
    },
  },
};
</script>
